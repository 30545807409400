import React, {useContext, useState} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Form/Button';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'SonstigesBetreuungskraft';
const title = 'Sonstige Wünsche?';

const SonstigesBetreuungskraft = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const [textArea, setTextArea] = useState();
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const handleChange = (event) => {
    const payload = {};
    payload[`Form${id}_Value`] = event.target.value;
    
    setTextArea(event.target.value);
    dispatch({type: 'UPDATE_FORM', payload: payload});
  }

  const showButton = () => state[`Form${id}`] === 'Sonstiges' ?
    <div className="row justify-content-md-center">
      <div className="col-12 col-md-4">
        <Button activeOn={[`Form${id}`]} onClick={submitForm} title="Weiter" />
      </div>
    </div> : null;

  const interaction = () => state[`Form${id}`] === 'Sonstiges' ? 
    <div className="row justify-content-center">
      <div className="col-12">
        <textarea className="form-control" onChange={handleChange} value={textArea}></textarea>
      </div>
    </div> : null;

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-4 mt-md-3">
          <CheckTile id={'Form'+id} title="Nichtraucher" icon="smoke_free" callback={submitForm} />
        </div>
        <div className="col-12 col-md-4 mt-3">
          <CheckTile id={'Form'+id} title="Tierlieb" icon="pets" callback={submitForm} />
        </div>
        <div className="col-12 col-md-4 mt-3">
          <CheckTile id={'Form'+id} title="Sonstiges" icon="hearing" interaction={interaction()} />
        </div>
      </div>
      {showButton()}
    </section>
  </> : null;

}

export {SonstigesBetreuungskraft as default, id, title};