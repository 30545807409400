import React, {useContext, useState} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Form/Button';
import StoreContext from '../storeContext';

const id = 'GeburtsdatumSenior';
const title = 'Geburtsdatum der zu betreuenden Person?';

const GeburtsdatumSenior = ({next, overrideId, overrideTitle, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const [birthdate, setBirthdate] = useState('1970-01-01');
  const componentId = overrideId ? overrideId : id;
  const componentTitle = overrideTitle ? overrideTitle : title;
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const handleChange = (event) => {
    const payload = {};
    payload[`Form${componentId}`] = event.target.value;

    dispatch({type: 'UPDATE_FORM', payload: payload});

    setBirthdate(event.target.value);
  }

  const getMax50Years = () => `${new Date().getUTCFullYear()-50}-01-01`;
  
  const getMin120Years = () => `${new Date().getUTCFullYear()-120}-01-01`;

  return state.visibleComponent === componentId ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <h2 className="font-weight-normal mb-5">{componentTitle}</h2>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4">
          <i className="material-icons display-2">cake</i>
          <input className="form-control btn-lg mt-3" type="date" min={getMin120Years()} max={getMax50Years()} value={birthdate} onChange={handleChange} />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4">
          <Button activeOn={[`Form${componentId}`]} onClick={submitForm} title="Weiter" />
        </div>
      </div>
    </section>
  </> : null;

}

export {GeburtsdatumSenior as default, id, title};