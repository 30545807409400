import React, { useContext } from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Form/Button';
import RadioButton from '../components/Form/RadioButton';
import StoreContext from '../storeContext';

const id = 'WobeiWirdDieUnterstuetzungBenoetigt';
const title = 'Wobei wird die Unterstützung benötigt?';

const WobeiWirdDieUnterstuetzungBenoetigt = ({next, overrideId, overrideTitle, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const componentId = overrideId ? overrideId : id;
  const componentTitle = overrideTitle ? overrideTitle : title;
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === componentId ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <h2 className="font-weight-normal">{componentTitle}</h2>
      <h3 className="font-weight-light mb-5">Mehrfachauswahl möglich</h3>
      <div className="row">
        <div className="col-12 col-md-6 col-lg-4">
          <RadioButton id={'Form'+componentId} title="Hauswirtschaft" />
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0 col-lg-4">
          <RadioButton id={'Form'+componentId} title="Einkaufen" />
        </div>
        <div className="col-12 col-md-6 mt-3 col-lg-4 mt-lg-0">
          <RadioButton id={'Form'+componentId} title="Arztbesuche" />
        </div>
        <div className="col-12 col-md-6 mt-3 col-lg-4">
          <RadioButton id={'Form'+componentId} title="Körperpflege" />
        </div>
        <div className="col-12 col-md-6 mt-3 col-lg-4">
          <RadioButton id={'Form'+componentId} title="Nachts" />
        </div>
        <div className="col-12 col-md-6 mt-3 col-lg-4">
          <RadioButton id={'Form'+componentId} title="Sonstiges" />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4">
          <Button activeOn={[`Form${componentId}`]} onClick={submitForm} title="Weiter" />
        </div>
      </div>
    </section>
  </> : null;

}

export {WobeiWirdDieUnterstuetzungBenoetigt as default, id, title};