import React, {useContext} from 'react';

import StoreContext from '../storeContext';

const VielenDank = () => {
  const {state} = useContext(StoreContext);

  return state.visibleComponent === 'VielenDank' ? 
    <section className="container text-center mt-3 mt-md-5">
      <div className="row">
        <div className="col-12">
          <i className="d-block material-icons md-48 display-2 mb-3 text-primary">done_all</i>
          <h2>Danke für Ihre Anfrage,<br/>{state.FormForename} {state.FormLastname}!</h2>
          <h3 className="mt-4">Wir bearbeiten Ihre Anfrage umgehend</h3>
          <p className="mt-4">Eine Zusammenfassung aller eingegebenen Daten haben wir soeben an die angegebene E-Mail Adresse <a href={'mailto:'+state.FormEmail}>{state.FormEmail}</a> gesendet. Bei Fragen stehen wir Ihnen gerne zur Verfügung.</p>
        </div>
      </div>
    </section> : null;
}

export default VielenDank;