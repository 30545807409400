import React, {useState, useEffect, useContext} from 'react';

import StoreContext from '../../storeContext';

const RadioButton = ({id, title}) => {
  const {state, dispatch} = useContext(StoreContext);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    state[id] && state[id].includes(title) ? setIsActive(true) : setIsActive(false);
  }, [state, id, title]);

  const handleClick = () => {
    const payload = {};

    payload[id] = state[id] ? [...state[id]] : [];

    state[id] && state[id].includes(title) ? 
      payload[id] = state[id].filter(foundItem => foundItem !== title) : 
      payload[id].push(title);

    dispatch({type: 'UPDATE_FORM', payload});
  }

  return isActive ? 
    <div className="shadow-sm rounded border p-2 cursor" onClick={handleClick}>
      <i className="material-icons text-secondary float-left mt-1 ml-2">check_circle</i>
      <p className="text-primary m-0">{title}</p>
    </div> : 
    <div className="shadow-sm rounded border p-2 cursor" onClick={handleClick}>
      <i className="material-icons text-secondary float-left mt-1 ml-2 d-none">check_circle</i>
      <p className="text-primary m-0">{title}</p>
    </div>
}

export default RadioButton;




// import React from 'react';

// class RadioButton extends React.Component {

//   constructor(props) {
//     super(props);

//     this.state = {
//       iconClasses: ['material-icons', 'text-secondary', 'float-left', 'mt-1', 'ml-2', 'd-none'],
//       tileClasses: ['shadow-sm', 'rounded', 'bg-light', 'p-2', 'cursor'],
//       titleClasses: ['text-primary', 'm-0']
//     }

//   }

//   static getDerivedStateFromProps = (props, state) => {

//     if (props.form[props.id] && props.form[props.id].includes(props.title)) {
//       return {
//         iconClasses: ['material-icons', 'text-secondary', 'float-left', 'mt-1', 'ml-2', 'd-block'],
//         tileClasses: ['shadow-sm', 'rounded', 'bg-light', 'p-2', 'cursor'],
//         titleClasses: ['text-primary', 'm-0']
//       }
//     }

//     return {
//       iconClasses: ['material-icons', 'text-secondary', 'float-left', 'mt-1', 'ml-2', 'd-none'],
//       tileClasses: ['shadow-sm', 'rounded', 'bg-light', 'p-2', 'cursor'],
//       titleClasses: ['text-primary', 'm-0']
//     }
//   }

//   handleClick = value => {
//     const formObject = {};
//     const storedValue = this.props.form[this.props.id];
//     formObject[this.props.id] = storedValue || [];

//     const found = formObject[this.props.id].find(element => element === value);

//     if (!found) {
//       formObject[this.props.id].push(value);
//       this.props.dispatch(setFormAction(formObject));
//     } else {
//       const filtered = formObject[this.props.id].filter(element => element !== value);
//       formObject[this.props.id] = filtered;
//       this.props.dispatch(setFormAction(formObject));
//     }
    
//     // this.props.dispatch(setShowStep1Action(false));
//     // this.props.dispatch(setShowStep2Action(true));
//   }

//   render = () => {
//     return (<div className={this.state.tileClasses.join(' ')} onClick={() => this.handleClick(this.props.title)}>
//       <i className={this.state.iconClasses.join(' ')}>check_circle</i>
//       <p className={this.state.titleClasses.join(' ')}>{this.props.title}</p>
//     </div>)
//   }
// }

// export default RadioButton;