import React from 'react';

const initialState = {
  FormCity: '',
  FormFuerWenSuchenSie: '',
  FormLiegenFolgendeErkrankungenVor: [],
  FormLiegtBereitsEinPflegegradVor: '',
  FormLiegtBereitsEinPflegegradVor_Pflegegrad: 0,
  FormWannSollDieBetreuungBeginnen: '',
  FormWannSollDieBetreuungBeginnen_Date: '',
  FormWieMobilIstDieZuBetreuendePerson: [],
  FormWobeiWirdDieUnterstuetzungBenoetigt: [],
  FormUnterbringungBetreuungskraft: '',
  FormZipcode: '',
  partner: {name: 'SENIOinfo24', website: 'https://www.senioinfo24.de', email: 'post@senioinfo24.de', datenschutz: 'https://www.senioinfo24.de/datenschutz'},
  showLoadingProcessText: 'Einen kleinen Moment bitte',
  visibleComponent: 'SearchForm',
};

function reducer(store, action) {
  switch (action.type) {
    case 'UPDATE_FORM':
      return {...store, ...action.payload};
    default:
      throw new Error();
  }
}

const StoreContext = React.createContext();

export { StoreContext as default, initialState, reducer };