import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'HilfeInDerNacht'; 
const title = 'Wird Hilfe in der Nacht benötigt?';

const IntervallButton = ({active, children, onClick}) => active ?
  <div className="btn border ml-2 bg-primary text-white">{children}</div> : 
  <div className="btn border ml-2 bg-light text-primary" onClick={() => onClick()}>{children}</div>;

const HilfeInDerNacht = ({next, overrideId, overrideTitle, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const componentId = overrideId ? overrideId : id;
  const componentTitle = overrideTitle ? overrideTitle : title;
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const setPflegegrad = (id, value) => {
    const payload = {};
    payload[id] = value;

    dispatch({type: 'UPDATE_FORM', payload: payload});

    submitForm();
  }

  const interactionHilfeIntervall = () => state[`Form${componentId}`] === 'Ja' ? 
    <>
      <div className="row mt-3">
        <div className="col-12 font-weight-bold">
          Wie oft pro Nacht?
        </div>
      </div>
      <div className="row justify-content-center my-3">
        <div className="col-12">
          <IntervallButton active={state[`Form${componentId}_Intervall`] === 1} onClick={() => setPflegegrad(`Form${componentId}_Intervall`, 1)}>1</IntervallButton>
          <IntervallButton active={state[`Form${componentId}_Intervall`] === 2} onClick={() => setPflegegrad(`Form${componentId}_Intervall`, 2)}>2</IntervallButton>
          <IntervallButton active={state[`Form${componentId}_Intervall`] === 3} onClick={() => setPflegegrad(`Form${componentId}_Intervall`, 3)}>3</IntervallButton>
          <IntervallButton active={state[`Form${componentId}_Intervall`] === 4} onClick={() => setPflegegrad(`Form${componentId}_Intervall`, 4)}>4</IntervallButton>
          <IntervallButton active={state[`Form${componentId}_Intervall`] === 5} onClick={() => setPflegegrad(`Form${componentId}_Intervall`, 5)}>5</IntervallButton>
        </div>
      </div>
    </> : null;

  return state.visibleComponent === componentId ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{componentTitle}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <CheckTile id={'Form'+componentId} title="Ja" icon="face" interaction={interactionHilfeIntervall()} />
        </div>
        <div className="col-12 mt-3 col-md-6 mt-md-0">
          <CheckTile id={'Form'+componentId} title="Nein" icon="accessibility" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {HilfeInDerNacht as default, id, title};