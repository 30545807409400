import React, {useState, useEffect, useContext} from 'react';

import StoreContext from '../../storeContext';

const Button = ({activeOn, onClick, title}) => {
  const {state} = useContext(StoreContext);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const found = activeOn.filter(formId => state[formId] && state[formId].length > 0);

    if (found && found.length === activeOn.length) {
      setIsActive(true);
    }
  }, [activeOn, state]);

  return isActive ? 
    <button type="button" className="mt-5 col-12 text-white shadow-sm btn bg-secondary btn-lg" onClick={onClick}>{title}</button> : 
    <button type="button" className="mt-5 col-12 text-white shadow-sm btn bg-secondary btn-lg" disabled="disabled">{title}</button>
}

export default Button;