import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'WannSollDieBetreuungBeginnen';
const title = 'Wann soll die Betreuung beginnen?';

const WannSollDieBetreuungBeginnen = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const setBeginnBetreuung = (id, event) => {
    const payload = {};
    payload[id] = event.target.value;

    dispatch({type: 'UPDATE_FORM', payload: payload});

    submitForm();
  }

  const interactionBeginnBetreuung = () => state[`Form${id}`] === 'Gewünschtes Datum' ? 
    <div className="row justify-content-center">
      <div className="col-12">
        <input className="form-control" type="date" value={state[`Form${id}_Date`]} onChange={(e) => setBeginnBetreuung(`Form${id}_Date`, e)} />
      </div>
    </div> : null;

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <CheckTile id={'Form'+id} title="Schnellstmöglich" icon="alarm" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-6 mt-3 mt-md-0">
          <CheckTile id={'Form'+id} title="innerhalb 14 Tage" icon="schedule" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-6 mt-3">
          <CheckTile id={'Form'+id} title="Später/Noch unklar" icon="update" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-6">
          <CheckTile id={'Form'+id} title="Gewünschtes Datum" icon="watch_later" interaction={interactionBeginnBetreuung()} />
        </div>
      </div>
    </section>
  </> : null;

}

export {WannSollDieBetreuungBeginnen as default, id, title};