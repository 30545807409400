import React, {useContext} from 'react';

import StoreContext from '../storeContext';

const FehlerVersand = () => {
  const {state} = useContext(StoreContext);

  return state.visibleComponent === 'FehlerVersand' ? 
    <section className="container text-center mt-3 mt-md-5">
      <div className="row">
        <div className="col-12">
          <i className="d-block material-icons md-48 display-2 mb-3 text-danger">error</i>
          <h2 className="text-danger">Es gab einen Fehler beim Versand,<br/>{state.FormForename} {state.FormLastname}!</h2>
          <h3 className="mt-4">Bitte versuchen Sie es erneut</h3>
          <p className="mt-4">Leider gab es einen Fehler beim Versand. Bitte füllen Sie das Formular erneut aus, oder kontaktieren Sie uns direkt unter <a href={'mailto:'+state.partner.email}>{state.partner.email}</a>.</p>
        </div>
      </div>
    </section> : null;
}

export default FehlerVersand;