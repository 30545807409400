import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'PflegekenntnisseBetreuungskraft';
const title = 'Gewünschte Pflegekenntnisse der Betreuungskraft?';

const PflegekenntnisseBetreuungskraft = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 mt-md-3">
          <CheckTile id={'Form'+id} title="Grundkenntnisse" icon="star_outline" callback={submitForm} />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <CheckTile id={'Form'+id} title="Mittel" icon="star_half" callback={submitForm} />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <CheckTile id={'Form'+id} title="Gut" icon="star" callback={submitForm} />
        </div>
        <div className="col-12 col-md-6 mt-3">
          <CheckTile id={'Form'+id} title="sehr gut" icon="verified_user" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {PflegekenntnisseBetreuungskraft as default, id, title};