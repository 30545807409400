import React, {useContext, useState} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import Button from '../components/Form/Button';
import StoreContext from '../storeContext';

const id = 'GewichtSenior';
const title = 'Gewicht der zu betreuenden Person?';

const GewichtSenior = ({next, overrideId, overrideTitle, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const componentId = overrideId ? overrideId : id;
  const componentTitle = overrideTitle ? overrideTitle : title;
  const [gewicht, setGewicht] = useState(0);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const handleChange = (event) => {
    const payload = {};
    payload[`Form${componentId}`] = event.target.value;

    dispatch({type: 'UPDATE_FORM', payload: payload});

    setGewicht(event.target.value);
  }

  return state.visibleComponent === componentId ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <h2 className="font-weight-normal mb-5">{componentTitle}</h2>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4">
          <i className="material-icons display-2">timeline</i>
          <input type="range" className="form-control-range p-1" min="40" max="150" value={gewicht} onChange={handleChange} />
          <h2 className="mt-3">{gewicht ? gewicht : 'Gewicht in'} kg</h2>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4">
          <Button activeOn={[`Form${componentId}`]} onClick={submitForm} title="Weiter" />
        </div>
      </div>
    </section>
  </> : null;

}

export {GewichtSenior as default, id, title};