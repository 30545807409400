import React, { useState, useEffect, useContext } from 'react';

import StoreContext from '../../storeContext';

const CheckTile = ({callback, desc, icon, id, interaction, title, visible = true}) => {
  const {state, dispatch} = useContext(StoreContext);
  const [styles, setStyles] = useState({
    iconClasses: [],
  });

  useEffect(() => {
    if (state[id] === title) {
      setStyles({
        iconClasses: ['text-secondary']
      });
    } else {
      setStyles({
        iconClasses: ['text-primary'],
      });
    }
  }, [state, id, title]);

  const handleClick = () => {
    let payload = {};
    payload[id] = title;

    dispatch({type: 'UPDATE_FORM', payload});

    if (callback) {
      callback();
    } 
  }

  return visible ? 
    <div className="shadow-sm rounded border p-2 cursor" onClick={handleClick}>
      <i className={"material-icons display-2 " + styles.iconClasses.join(' ')}>{icon}</i>
      <p className="text-primary">{title}</p>
      <span className="text-secondary">{desc}</span>
      {interaction}
    </div> : null;
}

export default CheckTile;