import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'WeiterePersonenImHaushalt';
const title = 'Weitere Personen im Haushalt?';

const WeiterePersonenImHaushalt = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = (secondPerson) => {
    const nextVisibleComponentOverwrite = secondPerson ? 'GeschlechtSenior2' : nextVisibleComponent;

    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponentOverwrite
    }});
  }

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <CheckTile id={'Form'+id} title="Die zu betreuende Person lebt alleine im Haushalt" desc="1 x Pflege / 1 x Haushaltshilfe" icon="person" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0">
          <CheckTile id={'Form'+id} title="Die Person lebt mit einer weiteren Person im Haushalt" desc="1 x Pflege / 2 x Haushaltshilfe" icon="people" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0">
          <CheckTile id={'Form'+id} title="Es handelt sich um 2 Personen die zu betreuen sind" desc="2 x Pflege / 2 x Haushaltshilfe" icon="supervisor_account" callback={() => submitForm('second_person')} />
        </div>
      </div>
    </section>
  </> : null;

}

export {WeiterePersonenImHaushalt as default, id, title};