import React, {useContext} from 'react';

import StoreContext from './storeContext';

const SearchFormError = () => {
  const { state } = useContext(StoreContext);

  return state.visibleComponent === 'SearchFormError' ? 
    <div className="container-fluid bg-warning p-4 shadow text-center">
      <p className="h6">Es gab einen internen Fehler. Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</p>
    </div> : null;
}

export default SearchFormError;