import React, { useContext } from 'react';

import StoreContext from '../storeContext';

const Spinner = () => {
  const { state } = useContext(StoreContext);

  return state.visibleComponent === 'Spinner' ?
    <div className="container">
      <div className="row justify-content-center">
        <div className="col-12 text-center mt-5 mt-md-3">
          <div className="lds-dual-ring" />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 text-center text-md-center">
          <p className="mt-3">{state.showLoadingProcessText ? state.showLoadingProcessText : <>Wir durchsuchen unsere Datenbank nach Ergebnissen in</>}</p>
          <h2>{state.FormCity}</h2>
        </div>
      </div>
    </div> : null;
}

export default Spinner;