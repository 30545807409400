import React, { useContext } from 'react';

import StoreContext from '../storeContext';

const Breadcrumb = ({visible = true, next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const previousComponentId = previous.shift();
  const previousComponentTitle = previous.pop();
  const nextComponentId = next ? next.shift() : [];
  const nextComponentTitle = next ? next.pop() : [];

  const goTo = (component) => {
    if (component) {
      dispatch({type: 'UPDATE_FORM', payload: {
        visibleComponent: component
      }});
    }
  }

  const getNextComponentLink = () => state[`Form${nextComponentId}`] ? 
    <span className="h6 font-weight-normal cursor" onClick={() => goTo(nextComponentId)}>{nextComponentTitle} &rarr;</span> : 
    <span className="h6 font-weight-normal">{nextComponentTitle} &rarr;</span>

  return visible ?
    <div className="container-fluid bg-light py-2">
      <div className="row">
        <div className="col-6">
          <span className="h6 font-weight-normal cursor" onClick={() => goTo(previousComponentId)}>&larr; {previousComponentTitle}</span>
        </div>
        <div className="col-6 text-right">
          {getNextComponentLink()}
        </div>
      </div>
    </div> : null;
}

export default Breadcrumb;