import React, {useContext, useState} from 'react';

import StoreContext from './storeContext';

const id = 'SearchForm';
const title = 'Suche starten';

const SearchForm = () => {
  const { state, dispatch } = useContext(StoreContext);
  const [showError, setShowError] = useState(false);

  const onEnter = event => {
    if (event.key === "Enter") {
      search();
    }
  }

  const search = async () => {
    const postalcode = document.getElementById('postalcode').value;

    if (postalcode.length < 4) {
      console.log(`POSTALCODE | ERROR | LENGTH`);
      return false;
    }

    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: 'Spinner',
      FormZipcode: postalcode
    }});
  
    const checkPostalCodeRequest = await fetch(`https://nominatim.openstreetmap.org/search?country=germany&postalcode=${postalcode}&addressdetails=1&format=json`);
    const checkPostalCodeResponse = await checkPostalCodeRequest.json();
    const identifiedLocationWithCity = checkPostalCodeResponse.find(location => location.address.municipality || location.address.city || location.address.town);

    const scrollToY = document.querySelector('.entry-content') ? document.querySelector('.entry-content').offsetTop : 0;

    if (!identifiedLocationWithCity) {
      console.log(`POSTALCODE | CITY | ERROR | NOT FOUND`);
      setShowError(true);
      dispatch({type: 'UPDATE_FORM', payload: {
        visibleComponent: id
      }});
      return false;
    }

    const identifiedCity = identifiedLocationWithCity.address.municipality ? identifiedLocationWithCity.address.municipality : identifiedLocationWithCity.address.city;

    dispatch({type: 'UPDATE_FORM', payload: {
      FormCity: identifiedCity,
      showLoadingProcessText: null
    }});

    if (checkPostalCodeResponse.length > 0) {
      setTimeout(() => {
        dispatch({type: 'UPDATE_FORM', payload: {
          visibleComponent: 'FuerWenSuchenSie'
        }});
  
        window.scrollTo(0, scrollToY);
      }, 3500);
    } else {
      dispatch({type: 'UPDATE_FORM', payload: {
        visibleComponent: id
      }});
  
      window.scrollTo(0, scrollToY);
    }
  }

  const showErrorForm = () => showError ? <React.Fragment>
    <div className="container mt-5 bg-danger p-3 rounded">
      <div className="row">
        <div className="col text-white">
          <p className="text-white font-weight-bold">Die angegebene Postleitzahl konnte nicht gefunden werden. Bitte versuchen Sie es erneut.</p>
          <p className="text-white">Sollte dieser Fehler weiterhin auftreten, senden Sie uns gerne eine Nachricht an post@seniopartner.de</p>
        </div>
      </div>
    </div>
  </React.Fragment> : null;

  return state.visibleComponent === 'SearchForm' ? <React.Fragment>
    {showErrorForm()}
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-12 col-md-6 col-lg-5">
          <div className="col-12">
            <input type="tel" className="form-control form-control-lg shadow-sm mt-3" id="postalcode" placeholder="Postleitzahl" onKeyPress={onEnter} autoFocus />
          </div>
          <div className="col-12 mt-2">
            <button type="button" className="col-12 text-white shadow-sm btn btn-lg bg-primary" onClick={search}>
              {title}
            </button>
          </div>
        </div>
      </div>
    </div>
  </React.Fragment> : null;
}

export {SearchForm as default, id, title};