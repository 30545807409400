import React, { useReducer, useEffect } from 'react';
import ReactDOM from 'react-dom';
// import reportWebVitals from './reportWebVitals';
import logger from 'use-reducer-logger';

import FuerWenSuchenSie, {id as FuerWenSuchenSieId, title as FuerWenSuchenSieTitle} from './steps/FuerWenSuchenSie';
import SearchForm, {id as SearchFormId, title as SearchFormTitle} from './SearchForm';
import VerhaeltnisBetreuendePerson, {id as VerhaeltnisBetreuendePersonId, title as VerhaeltnisBetreuendePersonTitle} from './steps/VerhaeltnisBetreuendePerson';
import WannSollDieBetreuungBeginnen, {id as WannSollDieBetreuungBeginnenId, title as WannSollDieBetreuungBeginnenTitle} from './steps/WannSollDieBetreuungBeginnen';
import DauerBetreuung, {id as DauerBetreuungId, title as DauerBetreuungTitle} from './steps/DauerBetreuung';
import GeschlechtSenior, {id as GeschlechtSeniorId, title as GeschlechtSeniorTitle} from './steps/GeschlechtSenior';
import GeburtsdatumSenior, {id as GeburtsdatumSeniorId, title as GeburtsdatumSeniorTitle} from './steps/GeburtsdatumSenior';
import GroesseSenior, {id as GroesseSeniorId, title as GroesseSeniorTitle} from './steps/GroesseSenior';
import GewichtSenior, {id as GewichtSeniorId, title as GewichtSeniorTitle} from './steps/GewichtSenior';
import LiegtBereitsEinPflegegradVor, {id as LiegtBereitsEinPflegegradVorId, title as LiegtBereitsEinPflegegradVorTitle} from './steps/LiegtBereitsEinPflegegradVor';
import LiegenFolgendeErkrankungenVor, {id as LiegenFolgendeErkrankungenVorId, title as LiegenFolgendeErkrankungenVorTitle} from './steps/LiegenFolgendeErkrankungenVor';
import WobeiWirdDieUnterstuetzungBenoetigt, {id as WobeiWirdDieUnterstuetzungBenoetigtId, title as WobeiWirdDieUnterstuetzungBenoetigtTitle} from './steps/WobeiWirdDieUnterstuetzungBenoetigt';
import WieMobilIstDieZuBetreuendePerson, {id as WieMobilIstDieZuBetreuendePersonId, title as WieMobilIstDieZuBetreuendePersonTitle} from './steps/WieMobilIstDieZuBetreuendePerson';
import UnterbringungBetreuungskraft, {id as UnterbringungBetreuungskraftId, title as UnterbringungBetreuungskraftTitle} from './steps/UnterbringungBetreuungskraft';
import GeschlechtBetreuungskraft, {id as GeschlechtBetreuungskraftId, title as GeschlechtBetreuungskraftTitle} from './steps/GeschlechtBetreuungskraft';
import DeutschkenntnisseBetreuungskraft, {id as DeutschkenntnisseBetreuungskraftId, title as DeutschkenntnisseBetreuungskraftTitle} from './steps/DeutschkenntnisseBetreuungskraft';
import Fuehrerschein, {id as FuehrerscheinId, title as FuehrerscheinTitle} from './steps/Fuehrerschein';
import WeiterePersonenImHaushalt, {id as WeiterePersonenImHaushaltId, title as WeiterePersonenImHaushaltTitle} from './steps/WeiterePersonenImHaushalt';
import HilfeInDerNacht, {id as HilfeInDerNachtId, title as HilfeInDerNachtTitle} from './steps/HilfeInDerNacht';
import AlterBetreuungskraft, {id as AlterBetreuungskraftId, title as AlterBetreuungskraftTitle} from './steps/AlterBetreuungskraft';
import PflegekenntnisseBetreuungskraft, {id as PflegekenntnisseBetreuungskraftId, title as PflegekenntnisseBetreuungskraftTitle} from './steps/PflegekenntnisseBetreuungskraft';
import SonstigesBetreuungskraft, {id as SonstigesBetreuungskraftId, title as SonstigesBetreuungskraftTitle} from './steps/SonstigesBetreuungskraft';
import IhrAngebot, {id as IhrAngebotId, title as IhrAngebotTitle} from './steps/IhrAngebot';
import VielenDank from './steps/VielenDank';
import FehlerVersand from './steps/FehlerVersand';
import SearchFormError from './SearchFormError';
import Spinner from './components/Spinner';

import './custom.scss';

import StoreContext, { initialState, reducer } from './storeContext';

const partnerList = [
  {name: 'SENIOpartner', website: 'https://seniopartner.de', email: 'post@seniopartner.de', datenschutz: 'https://seniopartner.de/datenschutzerklaerung/'},
];

const App = () => {
  const [state, dispatch] = useReducer(process.env.NODE_ENV === 'development' ? logger(reducer) : reducer, initialState);

  const ancestorOrigins = window.location.ancestorOrigins ? [...window.location.ancestorOrigins] : [];
  const partner = partnerList.find(partner => ancestorOrigins.includes(partner.website));

  // reportWebVitals(console.log);

  useEffect(() => {
    if (partner) {
      dispatch({type: 'UPDATE_FORM', payload: {
        partner
      }});
    }
  }, [partner]);

  const getPreviousGeschlechtBetreuungskraft = state['FormWeiterePersonenImHaushalt'] === 'Es handelt sich um 2 Personen die zu betreuen sind' ?
    ['WieMobilIstDieZuBetreuendePerson2', 'Wie mobil ist die 2. zu betreuende Person?'] :
    [WeiterePersonenImHaushaltId, WeiterePersonenImHaushaltTitle];

  const getNextWeiterePersonenImHaushalt = state['FormWeiterePersonenImHaushalt'] === 'Es handelt sich um 2 Personen die zu betreuen sind' ?
    ['GeschlechtSenior2', 'Geschlecht der 2. zu betreuenden Person?'] :
    [GeschlechtBetreuungskraftId, GeschlechtBetreuungskraftTitle];

  const getWeiterePersonImHaushalt = () => state['FormWeiterePersonenImHaushalt'] === 'Es handelt sich um 2 Personen die zu betreuen sind' ? <>
    <GeschlechtSenior 
      overrideId="GeschlechtSenior2" 
      overrideTitle="Geschlecht der 2. zu betreuenden Person" 
      previous={[WeiterePersonenImHaushaltId, WeiterePersonenImHaushaltTitle]} 
      next={['GeburtsdatumSenior2', 'Geburtsdatum der 2. zu betreuenden Person?']} 
    />

    <GeburtsdatumSenior 
      overrideId="GeburtsdatumSenior2" 
      overrideTitle="Geburtsdatum der 2. zu betreuenden Person?" 
      previous={['GeschlechtSenior2', 'Geschlecht der 2. zu betreuenden Person']} 
      next={['GroesseSenior2', 'Größe der 2. zu betreuenden Person?']} 
    />

    <GroesseSenior 
      overrideId="GroesseSenior2" 
      overrideTitle="Größe der 2. zu betreuenden Person?" 
      previous={['GeburtsdatumSenior2', 'Geburtsdatum der 2. zu betreuenden Person?']} 
      next={['GewichtSenior2', 'Gewicht der 2. zu betreuenden Person?']} 
    />

    <GewichtSenior 
      overrideId="GewichtSenior2" 
      overrideTitle="Gewicht der 2. zu betreuenden Person?" 
      previous={['GroesseSenior2', 'Größe der 2. zu betreuenden Person?']} 
      next={['LiegtBereitsEinPflegegradVor2', 'Liegt bereits ein Pflegegrad der 2. zu betreuenden Person vor?']} 
    />

    <LiegtBereitsEinPflegegradVor 
      overrideId="LiegtBereitsEinPflegegradVor2" 
      overrideTitle="Liegt bereits ein Pflegegrad der 2. zu betreuenden Person vor?" 
      previous={['GewichtSenior2', 'Gewicht der 2. zu betreuenden Person?']} 
      next={['LiegenFolgendeErkrankungenVor2', 'Liegen folgende Erkrankungen der 2. zu betreuenden Person vor?']} 
    />

    <LiegenFolgendeErkrankungenVor 
      overrideId="LiegenFolgendeErkrankungenVor2" 
      overrideTitle="Liegen folgende Erkrankungen der 2. zu betreuenden Person vor?" 
      previous={['LiegtBereitsEinPflegegradVor2', 'Liegt bereits ein Pflegegrad der 2. zu betreuenden Person vor?']} 
      next={['WobeiWirdDieUnterstuetzungBenoetigt2', 'Wobei wird die Unterstützung der 2. zu betreuenden Person benötigt?']} 
    />

    <WobeiWirdDieUnterstuetzungBenoetigt 
      overrideId="WobeiWirdDieUnterstuetzungBenoetigt2" 
      overrideTitle="Wobei wird die Unterstützung der 2. zu betreuenden Person benötigt?" 
      previous={['LiegenFolgendeErkrankungenVor2', 'Liegen folgende Erkrankungen der 2. zu betreuenden Person vor?']} 
      next={['HilfeInDerNacht2', 'Wird Hilfe von der 2. zu betreuenden Person in der Nacht benötigt?']} 
    />

    <HilfeInDerNacht 
      overrideId="HilfeInDerNacht2" 
      overrideTitle="Wird Hilfe von der 2. zu betreuenden Person in der Nacht benötigt?" 
      previous={['WobeiWirdDieUnterstuetzungBenoetigt2', 'Wobei wird die Unterstützung der 2. zu betreuenden Person benötigt?']} 
      next={['WieMobilIstDieZuBetreuendePerson2', 'Wie mobil ist die 2. zu betreuende Person?']} 
    />

    <WieMobilIstDieZuBetreuendePerson 
      overrideId="WieMobilIstDieZuBetreuendePerson2" 
      overrideTitle="Wie mobil ist die 2. zu betreuende Person?" 
      previous={['HilfeInDerNacht2', 'Wird Hilfe von der 2. zu betreuenden Person in der Nacht benötigt?']} 
      next={[GeschlechtBetreuungskraftId, GeschlechtBetreuungskraftTitle]} 
    />
  </> : null;

  return (
    <StoreContext.Provider value={{state, dispatch}}>
      
      <SearchFormError />
      <SearchForm />
      <Spinner />

      <FuerWenSuchenSie previous={[SearchFormId, SearchFormTitle]} next={[VerhaeltnisBetreuendePersonId, VerhaeltnisBetreuendePersonTitle]} />
      <VerhaeltnisBetreuendePerson previous={[FuerWenSuchenSieId, FuerWenSuchenSieTitle]} next={[WannSollDieBetreuungBeginnenId, WannSollDieBetreuungBeginnenTitle]} />
      <WannSollDieBetreuungBeginnen previous={[VerhaeltnisBetreuendePersonId, VerhaeltnisBetreuendePersonTitle]} next={[DauerBetreuungId, DauerBetreuungTitle]} />
      <DauerBetreuung previous={[WannSollDieBetreuungBeginnenId, WannSollDieBetreuungBeginnenTitle]} next={[GeschlechtSeniorId, GeschlechtSeniorTitle]} />
      <GeschlechtSenior previous={[DauerBetreuungId, DauerBetreuungTitle]} next={[GeburtsdatumSeniorId, GeburtsdatumSeniorTitle]} />
      <GeburtsdatumSenior previous={[GeschlechtSeniorId, GeschlechtSeniorTitle]} next={[GroesseSeniorId, GroesseSeniorTitle]} />
      <GroesseSenior previous={[GeburtsdatumSeniorId, GeburtsdatumSeniorTitle]} next={[GewichtSeniorId, GewichtSeniorTitle]} />
      <GewichtSenior previous={[GroesseSeniorId, GroesseSeniorTitle]} next={[LiegtBereitsEinPflegegradVorId, LiegtBereitsEinPflegegradVorTitle]} />
      <LiegtBereitsEinPflegegradVor previous={[GewichtSeniorId, GewichtSeniorTitle]} next={[LiegenFolgendeErkrankungenVorId, LiegenFolgendeErkrankungenVorTitle]} />
      <LiegenFolgendeErkrankungenVor previous={[LiegtBereitsEinPflegegradVorId, LiegtBereitsEinPflegegradVorTitle]} next={[WobeiWirdDieUnterstuetzungBenoetigtId, WobeiWirdDieUnterstuetzungBenoetigtTitle]} />
      <WobeiWirdDieUnterstuetzungBenoetigt previous={[LiegenFolgendeErkrankungenVorId, LiegenFolgendeErkrankungenVorTitle]} next={[HilfeInDerNachtId, HilfeInDerNachtTitle]} />
      <HilfeInDerNacht previous={[WobeiWirdDieUnterstuetzungBenoetigtId, WobeiWirdDieUnterstuetzungBenoetigtTitle]} next={[WieMobilIstDieZuBetreuendePersonId, WieMobilIstDieZuBetreuendePersonTitle]} />
      <WieMobilIstDieZuBetreuendePerson previous={[HilfeInDerNachtId, HilfeInDerNachtTitle]} next={[WeiterePersonenImHaushaltId, WeiterePersonenImHaushaltTitle]} />
      <WeiterePersonenImHaushalt previous={[WieMobilIstDieZuBetreuendePersonId, WieMobilIstDieZuBetreuendePersonTitle]} next={getNextWeiterePersonenImHaushalt} />

      {getWeiterePersonImHaushalt()}

      <GeschlechtBetreuungskraft previous={getPreviousGeschlechtBetreuungskraft} next={[AlterBetreuungskraftId, AlterBetreuungskraftTitle]} />
      <AlterBetreuungskraft previous={[GeschlechtBetreuungskraftId, GeschlechtBetreuungskraftTitle]} next={[DeutschkenntnisseBetreuungskraftId, DeutschkenntnisseBetreuungskraftTitle]} />
      <DeutschkenntnisseBetreuungskraft previous={[AlterBetreuungskraftId, AlterBetreuungskraftTitle]} next={[PflegekenntnisseBetreuungskraftId, PflegekenntnisseBetreuungskraftTitle]} />
      <PflegekenntnisseBetreuungskraft previous={[DeutschkenntnisseBetreuungskraftId, DeutschkenntnisseBetreuungskraftTitle]} next={[UnterbringungBetreuungskraftId, UnterbringungBetreuungskraftTitle]} />
      <UnterbringungBetreuungskraft previous={[PflegekenntnisseBetreuungskraftId, PflegekenntnisseBetreuungskraftTitle]} next={[FuehrerscheinId, FuehrerscheinTitle]} />
      <Fuehrerschein previous={[UnterbringungBetreuungskraftId, UnterbringungBetreuungskraftTitle]} next={[SonstigesBetreuungskraftId, SonstigesBetreuungskraftTitle]} />
      <SonstigesBetreuungskraft previous={[FuehrerscheinId, FuehrerscheinTitle]} next={[IhrAngebotId, IhrAngebotTitle]} />
      
      <IhrAngebot />
      <VielenDank />
      <FehlerVersand />

    </StoreContext.Provider>
  );
}

export default App;

ReactDOM.render(
  <App />, 
  document.getElementById('senioinfo24-widget')
);
