import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'FuerWenSuchenSie';
const title = 'Für wen suchen Sie?';

const FuerWenSuchenSie = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <h2 className="font-weight-normal">{title}</h2>
      <h3 className="font-weight-light mb-5">in {state.FormZipcode} {state.FormCity}</h3>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4 col-lg-3">
          <CheckTile id={'Form'+id} title="Für mich" icon="person" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="Für Angehörige" icon="people" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {FuerWenSuchenSie as default, id, title};