import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'VerhaeltnisBetreuendePerson';
const title = 'Verhältnis zur betreuenden Person?';

const VerhaeltnisBetreuendePerson = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary">
      <h2 className="font-weight-normal pt-4 mb-5">{title}</h2>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4 col-lg-3">
          <CheckTile id={'Form'+id} title="Partner" desc="Ehefrau/Ehemann" icon="supervisor_account" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="Kind" desc="Tochter/Sohn" icon="face" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="Bekannte" desc="Freundin/Freund" icon="person" callback={submitForm} />
        </div>
      </div>
      <div className="row justify-content-md-center mt-4">
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="gesetzlicher Vertreter" desc="Betreuer/Bevollmächtigter" icon="assignment_turned_in" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="sonstige Verwandtschaft" desc="Nichte/Neffe"  icon="group" callback={submitForm} />
        </div>
        <div className="col-12 mt-3 col-md-4 mt-md-0 col-lg-3">
          <CheckTile id={'Form'+id} title="Sonstiges" desc="Nicht aufgelistet" icon="help_outline" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {VerhaeltnisBetreuendePerson as default, id, title};