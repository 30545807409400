import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'DauerBetreuung';
const title = 'Voraussichtliche Dauer der Betreuung?';

const DauerBetreuung = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  const setBeginnBetreuung = (id, event) => {
    const payload = {};
    payload[id] = event.target.value;

    dispatch({type: 'UPDATE_FORM', payload: payload});

    submitForm();
  }

  const interaction = () => state[`Form${id}`] === 'zeitlich begrenzt' ? 
    <div className="row justify-content-center">
      <div className="col-12">
        <input className="form-control" type="date" value={state[`Form${id}_Date`]} onChange={(e) => setBeginnBetreuung(`Form${id}_Date`, e)} />
      </div>
    </div> : null;

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4">
          <CheckTile id={'Form'+id} title="Dauerhaft" icon="timer_off" callback={submitForm} />
        </div>
        <div className="col-12 col-md-4">
          <CheckTile id={'Form'+id} title="zeitlich begrenzt" icon="timelapse" interaction={interaction()} />
        </div>
        <div className="col-12 col-md-4">
          <CheckTile id={'Form'+id} title="Unklar" icon="help_outline" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {DauerBetreuung as default, id, title};