import React, { useContext } from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'UnterbringungBetreuungskraft';
const title = 'Gibt es eine Möglichkeit zur Unterbringung der Betreuungskraft?';

const UnterbringungBetreuungskraft = ({next, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const nextVisibleComponent = next[0];
  
  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === id ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal mb-5">{title}</h2>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-6">
          <CheckTile id={'Form'+id} title="Eigenes Zimmer" icon="hotel" callback={submitForm} />
        </div>
        <div className="col-12 col-md-6 mt-3 mt-md-0">
          <CheckTile id={'Form'+id} title="Wohnung" icon="location_city" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {UnterbringungBetreuungskraft as default, id, title};