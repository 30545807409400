import React, {useContext} from 'react';

import Breadcrumb from '../components/Breadcrumb';
import CheckTile from '../components/Form/CheckTile';
import StoreContext from '../storeContext';

const id = 'GeschlechtSenior';
const title = 'Geschlecht der zu betreuenden Person?';

const GeschlechtSenior = ({next, overrideId, overrideTitle, previous}) => {
  const { state, dispatch } = useContext(StoreContext);
  const componentId = overrideId ? overrideId : id;
  const componentTitle = overrideTitle ? overrideTitle : title;
  const nextVisibleComponent = next[0];

  const submitForm = () => {
    dispatch({type: 'UPDATE_FORM', payload: {
      visibleComponent: nextVisibleComponent
    }});
  }

  return state.visibleComponent === componentId ? <>
    <Breadcrumb previous={previous} next={next} />
    <section className="container text-center text-primary pt-4">
      <h2 className="font-weight-normal mb-5">{componentTitle}</h2>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-4 mt-3">
          <CheckTile id={'Form'+componentId} title="Weiblich" icon="person_outline" callback={submitForm} />
        </div>
        <div className="col-12 col-md-4 mt-md-3">
          <CheckTile id={'Form'+componentId} title="Männlich" icon="person" callback={submitForm} />
        </div>
      </div>
    </section>
  </> : null;

}

export {GeschlechtSenior as default, id, title};