import React, { useContext, useState } from 'react';

import StoreContext from '../storeContext';

const id = 'IhrAngebot';
const title = 'Ihr persönliches Angebot';

const IhrAngebot = () => {
  const { state, dispatch } = useContext(StoreContext);
  const [forename, setForename] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [datenschutz, setDatenschutz] = useState(false);

  const submitForm = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    for (const property in state) {
      if (property.includes('Form')) {
        formData.append(property, state[property]);
      }
    }

    formData.append('FormForename', forename);
    formData.append('FormLastname', lastname);
    formData.append('FormEmail', email);
    formData.append('FormPhone', phone);
    formData.append('FormDatenschutz', datenschutz);
    formData.append('FormPartnerName', state.partner.name);
    formData.append('FormPartnerEmail', state.partner.email);

    const request = await fetch('/php/sendmail.php', {
      method: 'POST',
      body: formData
    });

    const sendFormSuccessful = await request.json();

    if (sendFormSuccessful.success) { 
      dispatch({type: 'UPDATE_FORM', payload: {
        FormForename: forename,
        FormLastname: lastname,
        FormEmail: email,
        FormPhone: phone,
        FormDatenschutz: datenschutz,
        visibleComponent: 'VielenDank'
      }});
    } else {
      dispatch({type: 'UPDATE_FORM', payload: {
        FormForename: forename,
        FormLastname: lastname,
        FormEmail: email,
        FormPhone: phone,
        FormDatenschutz: datenschutz,
        visibleComponent: 'FehlerVersand'
      }});
    }
  }

  const getSubmitButton = () => forename && lastname && email && phone && datenschutz ? 
    <button type="submit" className="my-3 col-12 text-white shadow-sm btn bg-secondary btn-lg">Absenden →</button> : 
    <button type="submit" className="my-3 col-12 text-white shadow-sm btn bg-secondary btn-lg" disabled="disabled">Bitte alle Felder ausfüllen</button>;

  return state.visibleComponent === id ? <>
    <form onSubmit={submitForm} className="container text-center text-primary pt-4">
      <div className="row">
        <div className="col-12">
          <h2 className="font-weight-normal">{title}</h2>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-10">
          <p className="mt-3 mb-4">Bitte füllen Sie das unten stehende Formular aus damit wir Ihnen das Angebot zukommen lassen können.</p>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-5">
          <input type="text" className="form-control form-control-lg" placeholder="Vorname" value={forename} onChange={(e) => setForename(e.target.value)} required />
        </div>
        <div className="col-12 col-md-5">
          <input type="text" className="form-control form-control-lg mt-3 mt-md-0" placeholder="Nachname" value={lastname} onChange={(e) => setLastname(e.target.value)} required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-10">
          <input type="email" className="form-control form-control-lg mt-3" placeholder="E-Mail" value={email} onChange={(e) => setEmail(e.target.value)} required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-10">
          <input type="tel" className="form-control form-control-lg mt-3" placeholder="Ihre Telefonnummer" value={phone} onChange={(e) => setPhone(e.target.value)} required />
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-10">
          <p className="mt-4 text-left">
            <input type="checkbox" className="mr-2" value={state.partner.datenschutz} onChange={(e) => setDatenschutz(e.target.checked)} required />
            Ich habe die <a href={state.partner.datenschutz} target="_blank" rel="noreferrer">Datenschutzerklärung</a> zur Kenntnis genommen. Ich stimme zu, dass meine Angaben und Daten zur Beantwortung meiner Anfrage elektronisch erhoben und 
            gespeichert werden. Hinweis: Sie können Ihre Einwilligung jederzeit für die Zukunft per E-Mail an <a href={"mailto:" + state.partner.email}>{state.partner.email}</a> widerrufen.
          </p>
        </div>
      </div>
      <div className="row justify-content-md-center">
        <div className="col-12 col-md-5">
          {getSubmitButton()}
        </div>
      </div>
    </form>
  </> : null;

}

export {IhrAngebot as default, id, title};